<template>
    <div class="mymeeting_container">
        <div class="mymeeting_item_block mymeeting_user_information">
            <div class="mymeeting_user_name">{{$store.state.common.doctorInfo.name}}</div>
            <div class="mymeeting_hospital">
                <div class="hospital_name">{{$store.state.common.doctorInfo.hospital}}</div>
                <div class="hospital_split_line"></div>
                <div class="mymeeting_button">
                    <!-- <el-button type="primary" size="mini" >历史会议</el-button> -->
                    <customButton  @click="goToHistory"><div>历史会议</div></customButton>
                </div>
            </div>
        </div>
        <div class="mymeeting_listscroll">
            <listScroll ref="listScroll" :url="$urls.doctor.meetList" :select="search" :format="true">
                <template v-slot:listItem="props">
                    <div class="mymeeting_meetList_by_project_group_block">
                        <p class="mymeeting_meetList_by_project_group_classname">项目名称：{{props.citems.project_name}}</p>
                        <div v-for="(v,k) in props.citems.meetList" :key="k" class="mymeeting_meetList_by_project_group_meetlist">
                            <div class="mymeeting_item_block" @click="goToMeetingDetail(v)">
                                <meetItem :item="v"></meetItem>
                            </div>
                        </div>
                    </div>
                </template>
            </listScroll>
        </div>
    </div>
</template>
<script>
import customButton from '@/components/unit/customButton'
import listScroll from '@/components/unit/listScroll'
import meetItem from "@/components/unit/meetingItem"
import {ElButton} from 'element-plus';
export default {
    name:'meetingItem',
    data(){
        return {
            search:{
                project_id:this.$tools.getStorage("projectId",sessionStorage) || ""
            }
        }
    },
    created(){
        document.getElementsByTagName("title")[0].innerHTML = "我的会议"
    },
    components:{
        meetItem,
        listScroll,
        customButton,
        ElButton
    },
    methods:{
        goToHistory(){
            this.$router.push({
                path:"/historyMeeting"
            })
        },
        goToMeetingDetail(data){
            this.$router.push({
                path:"/meetDetail",
                query:{
                    id:data.id
                }
            })
        }
    }
}
</script>
<style>
.mymeeting_container{
    width: 100%;
    height: 100%;
    background: #f1f1f1;
    padding:5.33vw;
    /* padding: 9.2vw; */
}
.mymeeting_item_block{
    width: 100%;
    height: 23vw;
}
.mymeeting_user_information{
    background: #e0e0e0;
    padding:4vw;
    display: flex;
    flex-direction: column;
}
.mymeeting_user_name{
    font-size: 5vw;
    font-weight: 400;
}
.mymeeting_hospital{
    flex: 1 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 3.6vw;
    font-weight: 400;
}
.hospital_name{
    max-width: 50%;
}
.hospital_split_line{
    /* width: 22vw; */
    flex: 1 0 0;
    margin:0 4vw;
    border-bottom: 1px #b8b8b8 solid;
}
.mymeeting_button{
    height: 6.4vw;
    width: 19.6vw;
}
.mymeeting_button .el-button{
    width: 100%;
    height: 100%;
    border-radius: 1vw;
    background: url('../../../assets/images/icon/button.png');
    background-size: 100%;
}
 .mymeeting_button .el-button:hover{
    background: url('../../../assets/images/icon/button.png');
    background-size: 100%;
 }
 .mymeeting_listscroll{
     width: 100%;
     height: 85%;
 }
 .mymeeting_meetList_by_project_group_meetlist{
    margin-bottom: 3.2vw;
 }
 .mymeeting_meetList_by_project_group_block{
    background: #f9f9f9;
    padding: 4vw 2vw;
    width: 100%;
    margin-top: 4vw;
 }
 .mymeeting_meetList_by_project_group_classname{
    margin-bottom: 4vw;
 }
</style>
